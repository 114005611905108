import styled from "styled-components";
import Colors from "../../../styles/Colors";
import { StyledNumericWithCalculationInput } from "../../inputs";

const StyledH1 = styled.h1`
    color: ${Colors.greySecondaryText};
`;

const Label = styled.label`
    font-weight: 500;
`;

const Field = styled.div`
    display: flex;
    flex-direction: column;
    gap: 12px;
`;

const Actions = styled.div`
    display: flex;
    flex-direction: row;
    gap: 24px;
    align-items: center;
    padding-top: 16px;
`;

const Form = styled.form`
    display: flex;
    flex-direction: column;
    gap: 24px;
`;

const Group = styled.div`
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    gap: 8px;

    & > ${StyledNumericWithCalculationInput} {
        flex: 1 0 0;
    }
`;

export { Actions, Field, Form, Group, Label, StyledH1 };
