import React, { useState } from "react";
import { css, styled } from "styled-components";
import Colors from "../../styles/Colors";
import { bool, func, number, object, string } from "prop-types";
import { formatSummaryValue } from "../../helpers/utils";
import { Button } from "../Button";
import MonthlyComparison from "../MonthlyComparison";
import { MoneyPositionListView } from "../MoneyPositionList";
import { ArrowRight, ArrowDown, Show, Hide, Plus } from "../icons";
import Loader from "../Loader";
import { useNavigate } from "react-router-dom";

const DisplayButtonContainer = styled.div`
    position: absolute;
    right: 4px;
    top: 4px;
`;

const AddButtonContainer = styled.div`
    position: absolute;
    left: 4px;
    top: 4px;
`;

const ExpandIconContainer = styled.div`
    position: absolute;
    right: -18px;
    bottom: 16px;
`;

const Main = styled.div`
    display: flex;
    gap: 8px;
    justify-content: space-between;
    align-items: center;
    align-self: stretch;
`;

const Details = styled.div`
    ${({ $isExpanded }) => !$isExpanded && "display: none;"}
    ${({ $hasAdditionalPaddingBottom }) =>
        $hasAdditionalPaddingBottom && "padding-bottom: 24px;"}
`;

const Label = styled.div`
    min-width: 120px;
`;

const Sign = styled.div`
    font-size: 40px;
`;

const Primary = styled.div`
    font-weight: 400;
    font-size: 56px;
`;

const Secondary = styled.div`
    font-size: 40px;
`;

const Value = styled.div`
    flex: 1 0 0;
    align-self: stretch;

    display: flex;
    justify-content: flex-end;
    align-items: baseline;
    gap: 8px;
`;

const ValueContainer = styled.div`
    position: relative;
    height: 70px;

    svg path {
        stroke: ${Colors.greySecondaryText};
    }

    ${({ $withDetails }) => {
        if ($withDetails) {
            return css`
                &:hover {
                    cursor: pointer;
                    ${Value} {
                        color: ${Colors.black};
                    }
                    svg path {
                        stroke: ${Colors.black};
                    }
                }
            `;
        }
    }}
`;

const HiddenValue = styled.div`
    display: flex;
    gap: 8px;
    height: 100%;
    padding: 8px 0;

    div {
        width: 40px;
        height: calc(100% - 16px);
        border-radius: 8px;
        background-color: ${Colors.grey6PercentBackground};
    }
`;

const ComparisonContainer = styled.div`
    position: absolute;
    left: 0;
    right: 16px;
    bottom: 0;
    height: 24px;
`;

const StyledSummary = styled.section`
    position: relative;
    display: flex;
    gap: 24px;
    flex-direction: column;
    align-self: stretch;
    padding: 24px;
    border-radius: 8px;
    border: solid 1px ${Colors.grey10PercentBackground};
    color: ${Colors.greySecondaryText};

    ${AddButtonContainer}, ${DisplayButtonContainer}, ${ExpandIconContainer} {
        visibility: hidden;
    }

    &:hover {
        ${AddButtonContainer}, ${DisplayButtonContainer}, ${ExpandIconContainer} {
            visibility: visible;
        }
    }
`;

const SummaryView = ({
    data,
    addUrl,
    month,
    index,
    isVisible,
    handleVisibilityChange,
}) => {
    const [isExpanded, setIsExpanded] = useState(false);
    const navigate = useNavigate();

    if (!data) {
        return (
            <StyledSummary>
                <Loader width={`${40 + Math.round(40 * Math.random())}%`} />
            </StyledSummary>
        );
    }

    const { type, label, value, withSign, details, comparison } = data;

    const hiddenValueView = (
        <HiddenValue>
            <div></div>
            <div></div>
            <div></div>
        </HiddenValue>
    );

    let valueToRender = <></>;
    if (isVisible) {
        switch (type) {
            case "percent":
                valueToRender = (
                    <Value>
                        <Primary>{value}</Primary>
                        <Secondary>%</Secondary>
                    </Value>
                );
                break;
            case "number":
            default: {
                const { sign, primary, secondary } = formatSummaryValue(
                    value,
                    withSign
                );
                valueToRender = (
                    <Value>
                        {sign && <Sign>{sign}</Sign>}
                        <Primary>{primary}</Primary>
                        {secondary && <Secondary>{secondary}</Secondary>}
                    </Value>
                );
            }
        }
    } else {
        valueToRender = hiddenValueView;
    }

    const handleDisplayClick = () => handleVisibilityChange(!isVisible, index);

    const displayButton = (
        <DisplayButtonContainer>
            <Button
                view="link"
                iconLeft={isVisible ? <Show /> : <Hide />}
                onClick={handleDisplayClick}
            />
        </DisplayButtonContainer>
    );

    const handleAddClick = () => navigate(addUrl);

    const addButton = addUrl ? (
        <AddButtonContainer>
            <Button view="link" iconLeft={<Plus />} onClick={handleAddClick} />
        </AddButtonContainer>
    ) : (
        <></>
    );

    const hasComparisonList = Object.keys(comparison || {}).length > 0;

    const detailsToRender = details ? (
        <Details
            $isExpanded={isExpanded}
            $hasAdditionalPaddingBottom={hasComparisonList}
        >
            <MoneyPositionListView data={details} canWrap={false} />
        </Details>
    ) : (
        <></>
    );

    const valueContainer = details ? (
        <ValueContainer $withDetails onClick={() => setIsExpanded(!isExpanded)}>
            {valueToRender}
            <ExpandIconContainer>
                {isExpanded ? <ArrowDown /> : <ArrowRight />}
            </ExpandIconContainer>
        </ValueContainer>
    ) : (
        <ValueContainer>{valueToRender}</ValueContainer>
    );

    const comparisonToRender = hasComparisonList ? (
        <ComparisonContainer>
            <MonthlyComparison data={comparison} currentMonth={month} />
        </ComparisonContainer>
    ) : (
        <></>
    );

    return (
        <StyledSummary>
            <Main>
                <Label>{label}</Label>
                {valueContainer}
            </Main>
            {displayButton}
            {addButton}
            {detailsToRender}
            {comparisonToRender}
        </StyledSummary>
    );
};

SummaryView.propTypes = {
    data: object,
    addUrl: string,
    month: string,
    index: number,
    isVisible: bool,
    handleVisibilityChange: func,
};

export default SummaryView;
