import { array, bool, func, object, string } from "prop-types";
import React, { useEffect, useRef, useState } from "react";
import { createPortal } from "react-dom";
import styled, { css } from "styled-components";
import Colors from "../../../styles/Colors";
import { ArrowExpand } from "../../icons";
import { StyledInput } from "../../inputs";

const Placeholder = styled.span`
    color: ${Colors.greyDisabledText};
`;

const SelectButton = styled.button`
    appearance: none;
    flex: 1 0 0;
    width: 100%;
    min-width: 56px;
    padding: 8px 10px;

    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: 8px;

    background-color: ${Colors.white0};
    border: 1px solid ${Colors.grey10PercentBackground};
    border-radius: 4px;

    &:hover {
        cursor: pointer;
        border-color: ${Colors.grey16PercentBackground};
    }

    &:focus {
        outline: none;
        border-color: ${Colors.grey32PercentBackground};
    }
`;

const Option = styled.li`
    appearance: none;
    flex: 1 0 0;
    padding: 8px;

    &:hover {
        cursor: pointer;
        background-color: ${Colors.grey3PercentBackground};
    }
`;

const OptionsList = styled.ul`
    appearance: none;
    position: absolute;
    top: calc(4px + ${({ $top }) => $top}px);
    ${({ $right, $left }) => {
        if ($right) {
            return css`
                right: ${$right}px;
            `;
        }
        return css`
            left: ${$left}px;
        `;
    }}
    ${({ $maxHeight }) =>
        $maxHeight &&
        css`
            max-height: ${$maxHeight}px;
        `}
    min-width: ${({ $minWidth }) => $minWidth}px;
    padding: 2px;
    overflow-y: auto;

    display: flex;
    flex-direction: column;
    gap: 1px;

    background-color: ${Colors.white0};
    border: solid 1px ${Colors.grey10PercentBackground};
    border-radius: 4px;
`;

const StyledSelect = styled.div`
    display: flex;
    flex-wrap: wrap;
    gap: 8px;

    & > ${StyledInput} {
        flex: 1 0 0;
    }
`;

const OTHER_OPTION = { title: "Другое", value: "other" };

const Select = ({
    options,
    defaultOption,
    hasOther,
    otherOptionPlaceholder,
    onChange,
}) => {
    if (hasOther) {
        options = [...options, OTHER_OPTION];
    }

    const selectButtonRef = useRef(null);
    const optionsListRef = useRef(null);
    const otherOptionRef = useRef(null);
    const [selectedOption, setSelectedOption] = useState(defaultOption);
    const [otherValue, setOtherValue] = useState("");
    const [isDropdownOpen, setIsDropdownOpen] = useState(false);
    const [dropdownCoords, setDropdownCoords] = useState({
        top: 0,
        left: 0,
        minWidth: 100,
    });

    const toggleDropdown = (e) => {
        e.preventDefault();
        if (selectButtonRef.current) {
            const selectButtonRect =
                selectButtonRef.current.getBoundingClientRect();
            let top, left, right, maxHeight;

            if (selectButtonRect.right > window.innerWidth - 32) {
                right = window.innerWidth - selectButtonRect.right;
            } else {
                left = selectButtonRect.left;
            }

            maxHeight = window.innerHeight - selectButtonRect.bottom - 16;
            if (maxHeight < 112) {
                maxHeight = 112;
            }

            // FIXME: there is a problem with a top value when the window height is small
            top = selectButtonRect.bottom;
            if (window.innerHeight - selectButtonRect.bottom - 16 < maxHeight) {
                top = window.innerHeight - maxHeight - 16;
                if (top < 16) {
                    top = 16;
                }
            }

            setDropdownCoords({
                top,
                left,
                right,
                maxHeight,
                minWidth: selectButtonRect.width - 6,
            });
        }
        setIsDropdownOpen(!isDropdownOpen);
    };

    const handleOptionClick = (option) => {
        setSelectedOption(option);
        setIsDropdownOpen(false);
        onChange?.(option?.value);
    };

    const handleOtherOptionChange = (e) => {
        const value = e.target;
        setOtherValue(value);
        onChange?.(value);
    };

    // Close the dropdown when click outside of the control
    useEffect(() => {
        const handleClickOutside = (e) => {
            if (
                selectButtonRef.current &&
                !selectButtonRef.current.contains(e.target) &&
                optionsListRef.current &&
                !optionsListRef.current.contains(e.target)
            ) {
                setIsDropdownOpen(false);
            }
        };
        document.addEventListener("mousedown", handleClickOutside);
        return () =>
            document.removeEventListener("mousedown", handleClickOutside);
    }, []);

    useEffect(() => {
        if (defaultOption) {
            onChange?.(defaultOption.value);
        }
    }, []);

    // Set focus to the input when the other option has been selected
    useEffect(() => {
        if (selectedOption === OTHER_OPTION) {
            otherOptionRef.current?.focus();
        }
    }, [selectedOption]);

    return (
        <StyledSelect>
            <SelectButton onClick={toggleDropdown} ref={selectButtonRef}>
                {selectedOption ? (
                    selectedOption.title
                ) : (
                    <Placeholder>Выберите</Placeholder>
                )}{" "}
                <ArrowExpand strokeColor={Colors.greyDisabledText} />
            </SelectButton>
            {selectedOption === OTHER_OPTION && (
                <StyledInput
                    placeholder={otherOptionPlaceholder}
                    onChange={handleOtherOptionChange}
                    value={otherValue}
                    ref={otherOptionRef}
                />
            )}
            {isDropdownOpen &&
                createPortal(
                    <OptionsList
                        $top={dropdownCoords.top}
                        $left={dropdownCoords.left}
                        $right={dropdownCoords.right}
                        $maxHeight={dropdownCoords.maxHeight}
                        $minWidth={dropdownCoords.minWidth}
                        ref={optionsListRef}
                    >
                        {options.map((option) => (
                            <Option
                                key={option.value}
                                onClick={() => handleOptionClick(option)}
                            >
                                {option.title}
                            </Option>
                        ))}
                    </OptionsList>,
                    document.body
                )}
        </StyledSelect>
    );
};

Select.propTypes = {
    options: array.isRequired,
    defaultOption: object,
    hasOther: bool,
    otherOptionPlaceholder: string,
    onChange: func,
};

export default Select;
