import { useEffect, useState } from "react";
import { useAuth } from "../components/auth";
import axios from "axios";

const useData = (url) => {
    const [data, setData] = useState(undefined);
    const { signOut } = useAuth();

    const fetchData = async (abortController) => {
        setData(undefined);
        const config = { withCredentials: true };
        if (abortController) {
            config.signal = abortController.signal;
        }
        try {
            const response = await axios.get(url, config);
            setData(response.data);
        } catch (error) {
            if (error.response) {
                console.error(
                    `${error.response.status}: ${error.response.message}`
                );
                if (error.status === 403) {
                    await signOut();
                }
            } else if (error.request) {
                console.log(`No response for request: ${error.request}`);
            } else {
                console.error(`Request has problems: ${error.message}`);
            }
        }
    };

    useEffect(() => {
        const abortController = new AbortController();
        fetchData(abortController);
        return () => {
            abortController.abort();
        };
    }, [url]);

    return { data, fetchData };
};

export { useData };
