import React, { useState } from "react";
import DayExpenseAddFormView from "./DayExpenseAddFormView";
import { useData } from "../../../hooks";
import { getCurrentDate } from "../../../helpers/utils";

const POST_VARIABLE_EXPENSE_PATH = "/expense/variable";

const DEFAULT_EXPENSE = 0;
const DEFAULT_CURRENCY = "rub";
const DEFAULT_CATEGORY = "";
const DEFAULT_DESCRIPTION = "";
const DEFAULT_DATE = "today";

const currencies = [
    { value: "rub", title: "₽" },
    { value: "usd", title: "$" },
    { value: "thb", title: "฿" },
];

const categories = [
    { value: "🍕 Готовая еда", title: "🍕 Готовая еда" },
    { value: "🏡 Дом", title: "🏡 Дом" },
    { value: "💊 Здоровье", title: "💊 Здоровье" },
    { value: "🕹 Игры", title: "🕹 Игры" },
    { value: "🎬 Кино", title: "🎬 Кино" },
    { value: "🐈 Кошка", title: "🐈 Кошка" },
    { value: "✂️ Красота", title: "✂️ Красота" },
    { value: "🚗 Машина", title: "🚗 Машина" },
    { value: "🎓 Обучение", title: "🎓 Обучение" },
    { value: "👔 Одежда", title: "👔 Одежда" },
    { value: "🎁 Подарки", title: "🎁 Подарки" },
    { value: "🍞 Продукты", title: "🍞 Продукты" },
    { value: "🥳 Развлечения", title: "🥳 Развлечения" },
    { value: "📡 Связь", title: "📡 Связь" },
    { value: "🚎 Транспорт", title: "🚎 Транспорт" },
    { value: "🖥 Электроника", title: "🖥 Электроника" },
    { value: "❓ Другое", title: "❓ Другое" },
];

const dates = [
    { title: "Сегодня", value: "today" },
    { title: "Вчера", value: "yesterday" },
    { title: "Позавчера", value: "dayBeforeYesterday" },
];

const postFormData = async (data, setIsSubmitting, setSubmittingStatus) => {
    const url = `${process.env.REACT_APP_API_ENDPOINT}${POST_VARIABLE_EXPENSE_PATH}`;
    try {
        const response = await fetch(url, {
            method: "POST",
            headers: {
                "Content-Type": "application/json",
            },
            body: JSON.stringify(data),
            credentials: "include",
        });
        setSubmittingStatus(
            response.ok ? "Успешно добавили" : "Ошибка при добавлении"
        );
    } catch (error) {
        console.error(error);
        setSubmittingStatus("Ошибка при добавлении");
    } finally {
        setIsSubmitting(false);
    }
};

const DayExpenseAddForm = () => {
    const [expense, setExpense] = useState(DEFAULT_EXPENSE);
    const [currency, setCurrency] = useState(DEFAULT_CURRENCY);
    const [category, setCategory] = useState(DEFAULT_CATEGORY);
    const [description, setDescription] = useState(DEFAULT_DESCRIPTION);
    const [date, setDate] = useState(DEFAULT_DATE);
    const [submittingStatus, setSubmittingStatus] = useState(null);
    const [isSubmitting, setIsSubmitting] = useState(false);

    const budgetData = useData(
        `${
            process.env.REACT_APP_API_ENDPOINT
        }/expense/balance/day?date=${getCurrentDate()}`
    );

    const handleExpenseChange = (value) => {
        setExpense(value);
    };
    const handleCurrencyChange = (value) => {
        setCurrency(value);
    };
    const handleCategoryChange = (value) => {
        setCategory(value);
    };
    const handleDescriptionChange = (value) => {
        setDescription(value);
    };
    const handleDateChange = (value) => {
        setDate(value);
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        setIsSubmitting(true);
        setSubmittingStatus("Добавляем...");
        await postFormData(
            {
                expense,
                currency,
                category,
                description,
                date,
            },
            setIsSubmitting,
            setSubmittingStatus
        );
        await budgetData.fetchData();
    };

    return (
        <DayExpenseAddFormView
            handleExpenseChange={handleExpenseChange}
            handleCurrencyChange={handleCurrencyChange}
            handleCategoryChange={handleCategoryChange}
            handleDescriptionChange={handleDescriptionChange}
            handleDateChange={handleDateChange}
            handleSubmit={handleSubmit}
            isSubmitting={isSubmitting}
            submittingStatus={submittingStatus}
            currencies={currencies}
            categories={categories}
            dates={dates}
            budgetData={budgetData.data}
        />
    );
};

export default DayExpenseAddForm;
