import React, { forwardRef, useState } from "react";
import StyledInput from "../StyledInput/StyledInput";

const NumericInput = forwardRef((props, ref) => {
    const [value, setValue] = useState("");

    const handleChange = (e) => {
        const { value } = e.target;

        // Check the value
        if (/^\d*[.,]{0,1}\d*$/.test(value)) {
            setValue(value);
        }
    };

    return (
        <StyledInput
            type="text"
            inputMode="decimal"
            pattern="[0-9]+([.,][0-9]+)?"
            value={value}
            onChange={handleChange}
            ref={ref}
            {...props}
        />
    );
});

NumericInput.displayName = "NumericInput";

export default NumericInput;
