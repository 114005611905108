import React from "react";
import { func, string } from "prop-types";
import { useData } from "../../hooks";
import NavigationView from "./NavigationView";

const Navigation = ({ month, setMonth, width }) => {
    const { data } = useData(
        `${process.env.REACT_APP_API_ENDPOINT}/periods`
    );
    return (
        <NavigationView
            yearsAndMonths={data}
            selected={month}
            setMonth={setMonth}
            width={width}
        />
    );
};

Navigation.propTypes = {
    month: string.isRequired,
    setMonth: func.isRequired,
    width: string,
};

export default Navigation;
