import React from "react";
import { bool, func, number, object, string } from "prop-types";
import SummaryView from "./SummaryView";
import { useData } from "../../hooks";

const Summary = ({ url, month, index, isVisible, handleVisibilityChange }) => {
    const { data } = useData(url?.getData);
    return (
        <SummaryView
            data={data}
            addUrl={url?.add}
            month={month}
            index={index}
            isVisible={isVisible}
            handleVisibilityChange={handleVisibilityChange}
        ></SummaryView>
    );
};

Summary.propTypes = {
    url: object.isRequired,
    month: string,
    index: number,
    isVisible: bool,
    handleVisibilityChange: func,
};

export default Summary;
