import React, { useEffect, useRef } from "react";
import { array, bool, func, number, object, string } from "prop-types";
import { Button } from "../../Button";
import {
    Actions,
    Field,
    Form,
    Group,
    Label,
    StyledH1,
} from "../common/styledComponents";
import { NumericWithCalculationInput, StyledInput } from "../../inputs";
import { RadioSelect, Select } from "../../selects";
import styled from "styled-components";
import Loader from "../../Loader";
import Colors from "../../../styles/Colors";

const BudgetLabel = styled.div`
    font-weight: 500;
`;

const BudgetValue = styled.div`
    display: flex;
    align-items: center;

    color: ${({ $isNegative }) => ($isNegative ? Colors.red80 : Colors.green80)};
`;

const LoaderWrapper = styled.div`
    padding-top: 2px;
`;

const StyledBudget = styled.div`
    display: flex;
    gap: 16px;
    flex-wrap: wrap;
`;

const Budget = ({ value }) => {
    let isNegative = false;
    if (value < 0) {
        value = "–" + Math.abs(value);
        isNegative = true;
    }
    return (
        <StyledBudget>
            <BudgetLabel>Бюджет</BudgetLabel>
            <BudgetValue $isNegative={isNegative}>
                {value || (
                    <LoaderWrapper>
                        <Loader />
                    </LoaderWrapper>
                )}
            </BudgetValue>
        </StyledBudget>
    );
};

Budget.propTypes = {
    value: number,
};

const DayExpenseAddFormView = ({
    handleExpenseChange,
    handleCurrencyChange,
    handleCategoryChange,
    handleDescriptionChange,
    handleDateChange,
    handleSubmit,
    isSubmitting,
    submittingStatus,
    currencies,
    categories,
    dates,
    budgetData,
}) => {
    const expenseInputRef = useRef(null);

    useEffect(() => {
        expenseInputRef.current?.focus();
    }, []);

    return (
        <>
            <StyledH1>Новый дневной расход</StyledH1>
            <Form onSubmit={handleSubmit}>
                <Budget value={budgetData?.value} />
                <Field>
                    <Label htmlFor="expense">Сколько</Label>
                    <Group>
                        <NumericWithCalculationInput
                            name="expense"
                            id="expense"
                            placeholder="Размер расхода"
                            onChange={handleExpenseChange}
                            ref={expenseInputRef}
                        />
                        <Select
                            name="currency"
                            id="currency"
                            options={currencies}
                            defaultOption={currencies[0]}
                            hasOther={true}
                            otherOptionPlaceholder="Код валюты"
                            onChange={handleCurrencyChange}
                        />
                    </Group>
                </Field>
                <Field>
                    <Label htmlFor="category">Категория</Label>
                    <Select
                        name="category"
                        id="category"
                        options={categories}
                        defaultOption={categories[0]}
                        onChange={handleCategoryChange}
                    />
                </Field>
                <Field>
                    <Label htmlFor="description">Описание</Label>
                    <StyledInput
                        type="text"
                        name="description"
                        id="description"
                        onChange={(e) =>
                            handleDescriptionChange(e.target.value)
                        }
                    />
                </Field>
                <Field>
                    <Label htmlFor="date">Дата</Label>
                    <RadioSelect
                        name="date"
                        id="date"
                        options={dates}
                        defaultOption={dates[0]}
                        hasOther={true}
                        onChange={handleDateChange}
                    />
                </Field>
                <Actions>
                    <Button
                        view="background"
                        type="submit"
                        disabled={isSubmitting}
                    >
                        Добавить
                    </Button>
                    {submittingStatus && <div>{submittingStatus}</div>}
                </Actions>
            </Form>
        </>
    );
};

DayExpenseAddFormView.propTypes = {
    handleExpenseChange: func.isRequired,
    handleCurrencyChange: func.isRequired,
    handleCategoryChange: func.isRequired,
    handleDescriptionChange: func.isRequired,
    handleDateChange: func.isRequired,
    handleSubmit: func.isRequired,
    isSubmitting: bool,
    submittingStatus: string,
    currencies: array.isRequired,
    categories: array.isRequired,
    dates: array.isRequired,
    budgetData: object,
};

export default DayExpenseAddFormView;
