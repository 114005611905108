import React, { useState } from "react";
import {
    array,
    bool,
    element,
    func,
    object,
    oneOfType,
    string,
} from "prop-types";
import {
    StyledButton,
    StyledButtonBackground,
    StyledButtonLine,
    StyledButtonLink,
} from "./styledComponents";

const Button = ({
    view,
    children,
    iconLeft,
    iconRight,
    onClick,
    selected,
    disabled,
    ...props
}) => {
    const [isActive, setIsActive] = useState(false);

    const getRightIcon = () => {
        // If any right icon wasn't passed return an empty element
        if (!iconRight) {
            return <></>;
        }

        const defaultIconRight = iconRight.default || iconRight;
        const activeIconRight = iconRight.active || null;

        if (!isActive || !activeIconRight) {
            return defaultIconRight;
        }

        if (activeIconRight && isActive) {
            return activeIconRight;
        }
    };

    const handleClick = () => {
        setIsActive(!isActive);
        onClick?.();
    };

    let button = <></>;
    switch (view) {
        case "background":
            button = (
                <StyledButtonBackground
                    disabled={disabled}
                    $selected={selected}
                    onClick={handleClick}
                    {...props}
                >
                    {iconLeft}
                    {children}
                    {getRightIcon()}
                </StyledButtonBackground>
            );
            break;
        case "line":
            button = (
                <StyledButtonLine
                    disabled={disabled}
                    $selected={selected}
                    onClick={handleClick}
                    {...props}
                >
                    {iconLeft}
                    {children}
                    {getRightIcon()}
                </StyledButtonLine>
            );
            break;
        case "link":
            button = (
                <StyledButtonLink
                    disabled={disabled}
                    $selected={selected}
                    onClick={handleClick}
                    {...props}
                >
                    {iconLeft}
                    {children}
                    {getRightIcon()}
                </StyledButtonLink>
            );
            break;
        default:
            button = (
                <StyledButton
                    disabled={disabled}
                    $selected={selected}
                    onClick={handleClick}
                    {...props}
                >
                    {iconLeft}
                    {children}
                    {getRightIcon()}
                </StyledButton>
            );
    }

    return button;
};

Button.propTypes = {
    view: string,
    children: oneOfType([string, element, array]),
    iconLeft: element,
    iconRight: oneOfType([element, object]),
    onClick: func,
    selected: bool,
    disabled: bool,
};

export default Button;
