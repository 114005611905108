import React, { useEffect, useRef } from "react";
import { array, bool, func, string } from "prop-types";
import {
    Actions,
    Field,
    Form,
    Group,
    Label,
    StyledH1,
} from "../common/styledComponents";
import { Button } from "../../Button";
import { NumericWithCalculationInput, StyledInput } from "../../inputs";
import { RadioSelect, Select } from "../../selects";

const RevenueAddFormView = ({
    handleRevenueChange,
    handleCurrencyChange,
    handleDescriptionChange,
    handleDateChange,
    handleSubmit,
    isSubmitting,
    submittingStatus,
    currencies,
    dates,
}) => {
    const focused = useRef(null);
    const setDefaultFocus = () => focused?.current.focus();
    useEffect(setDefaultFocus, []);
    return (
        <>
            <StyledH1>Новая выручка</StyledH1>
            <Form onSubmit={handleSubmit}>
                <Field>
                    <Label htmlFor="revenue">Сколько</Label>
                    <Group>
                        <NumericWithCalculationInput
                            name="revenue"
                            id="revenue"
                            placeholder={"Размер выручки"}
                            onChange={handleRevenueChange}
                            ref={focused}
                        ></NumericWithCalculationInput>
                        <Select
                            name="currency"
                            id="currency"
                            options={currencies}
                            defaultOption={currencies[0]}
                            hasOther={true}
                            otherOptionPlaceholder="Код валюты"
                            onChange={handleCurrencyChange}
                        />
                    </Group>
                </Field>
                <Field>
                    <Label htmlFor="description">Описание</Label>
                    <StyledInput
                        type="text"
                        name="description"
                        id="description"
                        onChange={(e) =>
                            handleDescriptionChange(e.target.value)
                        }
                    />
                </Field>
                <Field>
                    <Label htmlFor="date">Месяц</Label>
                    <RadioSelect
                        name="date"
                        id="date"
                        options={dates}
                        defaultOption={dates[0]}
                        hasOther={true}
                        onChange={handleDateChange}
                    />
                </Field>
                <Actions>
                    <Button
                        view="background"
                        type="submit"
                        disabled={isSubmitting}
                    >
                        Добавить
                    </Button>
                    {submittingStatus && <div>{submittingStatus}</div>}
                </Actions>
            </Form>
        </>
    );
};

RevenueAddFormView.propTypes = {
    handleRevenueChange: func.isRequired,
    handleCurrencyChange: func.isRequired,
    handleDescriptionChange: func.isRequired,
    handleDateChange: func.isRequired,
    handleSubmit: func.isRequired,
    isSubmitting: bool,
    submittingStatus: string,
    currencies: array.isRequired,
    dates: array.isRequired,
};

export default RevenueAddFormView;
