import React, { useState } from "react";
import { array, func, object, oneOfType, string } from "prop-types";
import { styled } from "styled-components";
import { Button, LinkExpandable } from "../Button";
import Colors from "../../styles/Colors";
import Loader from "../Loader";
import Sizes from "../../styles/Sizes";

const StyledNavigation = styled.nav`
    display: flex;
    flex-direction: column;
    gap: 16px;
    padding: 8px 0 0 0;
    min-width: 80px;

    ${({ $width }) => $width && `width: ${$width};`}
    ${({ $disabled }) => $disabled && `color: ${Colors.greyDisabledText};`}

    @media (width <= ${Sizes.widthDesktopNarrow1280}) {
        padding: 8px 8px 16px 0;
        flex-direction: row;
        width: 100%;
        overflow-x: auto;
    }
`;

const NavigationView = ({
    yearsAndMonths,
    selected,
    setMonth,
    width,
}) => {
    if (!yearsAndMonths) {
        return (
            <StyledNavigation $width={width} $disabled>
                <Loader width="100%" />
            </StyledNavigation>
        );
    }
    if (yearsAndMonths.length === 0) {
        return (
            <StyledNavigation $width={width} $disabled>
                Нет данных
            </StyledNavigation>
        );
    }
    return (
        <StyledNavigation $width={width}>
            {Object.entries(yearsAndMonths)
                // Sort years by a descending order
                .sort(([y1], [y2]) => parseInt(y2) - parseInt(y1))
                .map(([year, months]) => {
                    return (
                        <Year
                            key={year}
                            year={year}
                            months={months}
                            selected={selected}
                            setMonth={setMonth}
                        ></Year>
                    );
                })}
        </StyledNavigation>
    );
};

NavigationView.propTypes = {
    yearsAndMonths: oneOfType([object, array]),
    selected: string.isRequired,
    setMonth: func.isRequired,
    width: string,
};

const MonthList = styled.ul`
    display: flex;
    flex-direction: column;
    gap: 8px;

    ${({ $hidden }) => $hidden && `display: none;`}

    @media (width <= ${Sizes.widthDesktopNarrow1280}) {
        flex-direction: row;
    }
`;

const YearFixedWidthWrapper = styled.span`
    width: 44px;
`;

const StyledYear = styled.div`
    display: flex;
    flex-direction: column;
    gap: 8px;

    @media (width <= ${Sizes.widthDesktopNarrow1280}) {
        flex-direction: row;
    }
`;

const Year = ({ year, months, selected, setMonth }) => {
    const defaultIsExpanded = months.find((month) => month.value === selected)
        ? true
        : false;
    const [isExpanded, setIsExpanded] = useState(defaultIsExpanded);

    const today = new Date();
    const isCurrentYear = today.getFullYear().toString() === year.toString();

    return (
        <StyledYear>
            {/* Show an expanding button for each year except the current  */}
            {!isCurrentYear && (
                <LinkExpandable
                    defaultIsExpanded={isExpanded}
                    onClick={() => setIsExpanded(!isExpanded)}
                >
                    <YearFixedWidthWrapper>{year}</YearFixedWidthWrapper>
                </LinkExpandable>
            )}

            {/* The month list for current year is always visible */}
            <MonthList $hidden={!isCurrentYear && !isExpanded}>
                {months.map(({ label, value }) => (
                    <li key={value}>
                        {
                            <Button
                                view="link"
                                selected={value === selected}
                                onClick={() => setMonth(value)}
                            >
                                {label}
                            </Button>
                        }
                    </li>
                ))}
            </MonthList>
        </StyledYear>
    );
};

Year.propTypes = {
    year: string.isRequired,
    months: array.isRequired,
    selected: string.isRequired,
    setMonth: func.isRequired,
};

export default NavigationView;
