import React, { useEffect, useRef } from "react";
import {
    Actions,
    Field,
    Form,
    Label,
    StyledH1,
} from "../common/styledComponents";
import { array, bool, func, string } from "prop-types";
import { Button } from "../../Button";
import { NumericWithCalculationInput } from "../../inputs";
import { RadioSelect } from "../../selects";

const ReservationAddFormView = ({
    handleReservationChange,
    handleValueTypeChange,
    handleDateChange,
    handleSubmit,
    isSubmitting,
    submittingStatus,
    valueTypes,
    dates,
}) => {
    const focused = useRef(null);
    const setDefaultFocus = () => focused?.current.focus();
    useEffect(setDefaultFocus, []);
    return (
        <>
            <StyledH1>Новое накопление</StyledH1>
            <Form onSubmit={handleSubmit}>
                <Field>
                    <Label htmlFor="reservation">Сколько</Label>
                    <NumericWithCalculationInput
                        name="reservation"
                        id="reservation"
                        placeholder="Размер накопления"
                        onChange={handleReservationChange}
                        ref={focused}
                    />
                </Field>
                <Field>
                    <Label htmlFor="valueType">Тип значения</Label>
                    <RadioSelect
                        name="valueType"
                        id="valueType"
                        options={valueTypes}
                        defaultOption={valueTypes[0]}
                        onChange={handleValueTypeChange}
                    />
                </Field>
                <Field>
                    <Label htmlFor="date">Месяц</Label>
                    <RadioSelect
                        name="date"
                        id="date"
                        options={dates}
                        defaultOption={dates[0]}
                        onChange={handleDateChange}
                        hasOther={true}
                    />
                </Field>
                <Actions>
                    <Button
                        view="background"
                        type="submit"
                        disabled={isSubmitting}
                    >
                        Добавить
                    </Button>
                    {submittingStatus && <div>{submittingStatus}</div>}
                </Actions>
            </Form>
        </>
    );
};

ReservationAddFormView.propTypes = {
    handleReservationChange: func,
    handleValueTypeChange: func,
    handleDateChange: func,
    handleSubmit: func.isRequired,
    isSubmitting: bool,
    submittingStatus: string,
    valueTypes: array.isRequired,
    dates: array.isRequired,
};

export default ReservationAddFormView;
