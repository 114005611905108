import React, { useState } from "react";
import { array, bool } from "prop-types";
import { styled } from "styled-components";
import Colors from "../../styles/Colors";
import { formatByDigits } from "../../helpers/utils";

const Position = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    gap: 4px;
    flex-wrap: wrap;
`;

const Line = styled.div`
    display: flex;
    padding-top: 4px;
    align-items: center;
    flex: 1 0 0;
    align-self: stretch;

    div {
        height: 1px;
        border: dashed 0.5px ${Colors.greyDisabledText};
        flex: 1 0 0;
        box-sizing: border-box;
    }
`;

const Container = styled.div`
    display: flex;
    align-content: flex-start;
    align-self: stretch;
    gap: 16px 48px;

    ${({ $canWrap }) =>
        $canWrap
            ? `
                flex-wrap: wrap;
                align-items: flex-start;
                ${Position} {
                    width: 296px;
                }
            `
            : `
                flex-direction: column;
                ${Position} {
                    flex-grow: 1;
                    ${Line} {
                        flex-grow: 1;
                    }
                }
            `}

    color: ${Colors.greySecondaryText};
    ${({ $disabled }) => $disabled && `color: ${Colors.greyDisabledText};`}
`;

const Value = styled.span`
    color: ${Colors.black};
`;

const ValueContainer = styled.div`
    display: flex;
    gap: 4px;
    flex: 1 0 0;
`;

const ItemsContainer = styled.div`
    align-self: stretch;

    display: flex;
    flex-direction: row;
    align-items: stretch;
    gap: 4px;

    white-space: nowrap;

    &:hover {
        cursor: pointer;
    }
`;

const ItemCollapsed = styled.div`
    padding: 8px 0 6px;
    align-self: stretch;

    div {
        width: 2px;
        height: 100%;
        background-color: ${Colors.grey16PercentBackground};
        border-radius: 8px;
    }

    ${ItemsContainer}:hover & div {
        background-color: ${Colors.grey32PercentBackground};
    }
`;

const Items = ({ items }) => {
    const [isExpanded, setIsExpanded] = useState(false);

    const getCollapsedItems = () => {
        return items.map((el, index) => (
            <ItemCollapsed key={index}>
                <div />
            </ItemCollapsed>
        ));
    };

    return (
        <ItemsContainer onClick={() => setIsExpanded(!isExpanded)}>
            {isExpanded ? `${items.join("+")} =` : getCollapsedItems()}
        </ItemsContainer>
    );
};

Items.propTypes = {
    items: array.isRequired,
};

const MoneyPositionListView = ({ data, canWrap }) => {
    canWrap = canWrap === undefined ? true : canWrap;

    if (!data) {
        return <Container $disabled>Загрузка...</Container>;
    }
    if (data.length === 0) {
        return <Container $disabled>Нет данных</Container>;
    }

    return (
        <Container $canWrap={canWrap}>
            {data.map(({ label, value, items }, index) => (
                <Position key={index}>
                    <span>{label}</span>
                    <ValueContainer>
                        <Line>
                            <div />
                        </Line>
                        {items && items.length > 1 && <Items items={items} />}
                        <Value>{formatByDigits(value)}</Value>
                    </ValueContainer>
                </Position>
            ))}
        </Container>
    );
};

MoneyPositionListView.propTypes = {
    data: array,
    canWrap: bool,
};

export default MoneyPositionListView;
