import React, { useState } from "react";
import PermanentExpenseAddFormView from "./PermanentExpenseAddFormView";

const POST_PERMANENT_EXPENSE_PATH = "/expense/permanent";

const DEFAULT_EXPENSE = 0;
const DEFAULT_CURRENCY = "rub";
const DEFAULT_DESCRIPTION = "";
const DEFAULT_DATE = "today";

const currencies = [
    { value: "rub", title: "₽" },
    { value: "usd", title: "$" },
    { value: "thb", title: "฿" },
];

const dates = [
    { title: "Текущий", value: "currentMonth" },
    { title: "Прошлый", value: "previousMonth" },
];

const PermanentExpenseAddForm = () => {
    const [expense, setExpense] = useState(DEFAULT_EXPENSE);
    const [currency, setCurrency] = useState(DEFAULT_CURRENCY);
    const [description, setDescription] = useState(DEFAULT_DESCRIPTION);
    const [date, setDate] = useState(DEFAULT_DATE);
    const [submittingStatus, setSubmittingStatus] = useState(null);
    const [isSubmitting, setIsSubmitting] = useState(false);

    const handleExpenseChange = (value) => {
        setExpense(value);
    };
    const handleCurrencyChange = (value) => {
        setCurrency(value);
    };
    const handleDescriptionChange = (value) => {
        setDescription(value);
    };
    const handleDateChange = (value) => {
        setDate(value);
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        setIsSubmitting(true);
        setSubmittingStatus("Добавляем...");
        const data = {
            expense,
            currency,
            description,
            date,
        };
        const url = `${process.env.REACT_APP_API_ENDPOINT}${POST_PERMANENT_EXPENSE_PATH}`;
        try {
            const response = await fetch(url, {
                method: "POST",
                headers: {
                    "Content-Type": "application/json",
                },
                body: JSON.stringify(data),
                credentials: "include",
            });
            setSubmittingStatus(
                response.ok ? "Успешно добавили" : "Ошибка при добавлении"
            );
        } catch (error) {
            console.error(error);
            setSubmittingStatus("Ошибка при добавлении");
        } finally {
            setIsSubmitting(false);
        }
    };

    return (
        <PermanentExpenseAddFormView
            handleExpenseChange={handleExpenseChange}
            handleCurrencyChange={handleCurrencyChange}
            handleDescriptionChange={handleDescriptionChange}
            handleDateChange={handleDateChange}
            handleSubmit={handleSubmit}
            isSubmitting={isSubmitting}
            submittingStatus={submittingStatus}
            currencies={currencies}
            dates={dates}
        />
    );
};

export default PermanentExpenseAddForm;
