import { array, bool, func, object } from "prop-types";
import React, { useEffect, useRef, useState } from "react";
import styled from "styled-components";
import { StyledInput } from "../../inputs";
import { Button, StyledButton } from "../../Button";
import Colors from "../../../styles/Colors";

const Container = styled.div`
    display: flex;
    flex-direction: column;
    gap: 8px;
`;

const OptionsList = styled.ul`
    padding: 4px 0;

    display: flex;
    flex-wrap: wrap;
    gap: 8px;

    & ${StyledButton}:not(:last-of-type)::after {
        content: "·";
        padding-left: 4px;
        color: ${Colors.grey16PercentBackground};
    }
`;

const OTHER_OPTION = { title: "Другое", value: "other" };

const RadioSelect = ({ options, defaultOption, hasOther, onChange }) => {
    if (hasOther) {
        options = [...options, OTHER_OPTION];
    }

    const [selectedOption, setSelectedOption] = useState(defaultOption);
    const [otherValue, setOtherValue] = useState("");

    const otherInputRef = useRef(null);

    const handleOptionChange = (option) => {
        setSelectedOption(option);
        onChange?.(option.value);
    };

    const handleOtherChange = (e) => {
        const { value } = e.target;
        setOtherValue(value);
        onChange?.(value);
    };

    useEffect(() => {
        if (selectedOption === OTHER_OPTION) {
            otherInputRef.current?.focus();
        }
    }, [selectedOption]);

    return (
        <Container>
            <OptionsList>
                {options.map((option) => (
                    <Button
                        key={option.value}
                        type="button"
                        view="link"
                        $selected={selectedOption === option}
                        onClick={() => handleOptionChange(option)}
                    >
                        {option.title}
                    </Button>
                ))}
                {/* {idx + 1 !== options.length && (
                            <Divider key={`divider${idx}`} />
                        )} */}
            </OptionsList>

            {selectedOption === OTHER_OPTION && (
                <StyledInput
                    ref={otherInputRef}
                    type="text"
                    value={otherValue}
                    onChange={handleOtherChange}
                />
            )}
        </Container>
    );
};

RadioSelect.propTypes = {
    options: array.isRequired,
    defaultOption: object,
    hasOther: bool,
    onChange: func,
};

export default RadioSelect;
