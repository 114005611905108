import React, { forwardRef, useState } from "react";
import StyledInput from "../StyledInput/StyledInput";
import styled from "styled-components";
import Colors from "../../../styles/Colors";
import { func } from "prop-types";

const StyledNumericWithCalculationInput = styled.div`
    display: flex;
    position: relative;

    & ${StyledInput} {
        flex: 1 0 0;
    }
`;

const Precalculated = styled.div`
    position: absolute;
    right: 10px;
    color: ${Colors.greyDisabledText};
    top: calc(50% - 11px);
`;

const NumericWithCalculationInput = forwardRef(
    ({ onChange, ...props }, ref) => {
        const [value, setValue] = useState("");
        const [calculated, setCalculated] = useState(0);

        const handleChange = (e) => {
            let { value } = e.target;
            let calculated = 0;

            // Check the value
            if (!/^[0-9.,+-]*$/.test(value)) {
                return;
            }

            setValue(value);

            // Remove a trailing plus or minus from the value
            if (value.endsWith("+") || value.endsWith("-")) {
                value = value.slice(0, value.length - 1);
            }

            // Check that value contains a valid expression
            if (/^\d+([.,]\d+)?([+-]\d+([.,]\d+)?)*$/.test(value)) {
                value = String(value).replaceAll(",", ".");
                calculated = eval(value);
            }

            // Use zero as a calculated value if there is no value at all
            if (value.length === 0) {
                calculated = 0;
            }

            setCalculated(calculated);
            onChange?.(calculated);
        };

        return (
            <StyledNumericWithCalculationInput>
                <StyledInput
                    type="text"
                    inputMode="tel"
                    pattern="^\d+([.,]\d+)?([+-]\d+([.,]\d+)?)*$"
                    value={value}
                    data-calculated={calculated}
                    onChange={handleChange}
                    ref={ref}
                    {...props}
                />
                <Precalculated>= {calculated}</Precalculated>
            </StyledNumericWithCalculationInput>
        );
    }
);

NumericWithCalculationInput.displayName = "NumericWithCalculationInput";

NumericWithCalculationInput.propTypes = {
    onChange: func,
};

export default NumericWithCalculationInput;

export { StyledNumericWithCalculationInput };
