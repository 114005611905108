import { css, styled } from "styled-components";
import Colors from "../../styles/Colors";

const StyledButton = styled.button`
    appearance: none;
    padding: 0;

    display: flex;
    align-items: center;
    gap: 4px;

    background-color: unset;
    border: none;
    font-size: 16px;
    font-weight: 300;
    text-align: left;
    color: ${Colors.black};

    &:hover {
        cursor: pointer;
    }

    &:disabled {
        cursor: initial;
        color: ${Colors.grey32PercentBackground};
    }
`;

const StyledButtonBackground = styled(StyledButton)`
    padding: 8px 12px;

    background-color: ${Colors.grey6PercentBackground};
    border-radius: 6px;

    &:not(:disabled):hover {
        background-color: ${Colors.grey10PercentBackground};
    }

    &:active {
        background-color: ${Colors.grey16PercentBackground};
    }
`;

const StyledButtonLink = styled(StyledButton)`
    color: ${Colors.greyDisabledText};

    svg path {
        stroke: ${Colors.greyDisabledText};
    }

    &:not(:disabled):hover {
        color: ${Colors.black};
        svg path {
            stroke: ${Colors.black};
        }
    }

    ${({ $selected }) => $selected && `color: ${Colors.black};`}
`;

const StyledButtonLine = styled(StyledButton)`
    padding: 8px 12px;

    border: solid 1px ${Colors.grey10PercentBackground};
    border-radius: 6px;

    &:not(:disabled):hover {
        border-color: ${Colors.grey16PercentBackground};
    }

    &:active {
        border-color: ${Colors.grey16PercentBackground};
    }

    ${({ $selected }) =>
        $selected &&
        css`
            border-color: ${Colors.grey32PercentBackground};
            color: ${Colors.black};

            &:not(:disabled):hover {
                border-color: ${Colors.grey32PercentBackground};
            }
        `}
`;

const Animated = styled.span`
    display: flex;
    align-self: center;
    transition: transform 0.2s ease;
    ${({ $isExpanded }) => $isExpanded && `transform: rotateZ(90deg);`}
`;

export {
    Animated,
    StyledButton,
    StyledButtonBackground,
    StyledButtonLine,
    StyledButtonLink,
};
